import { memo, useContext, useMemo } from 'react'

import { Avatar, Box, Typography } from '@mui/material'
import type { DayObject } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { useAvatarCache } from 'src/components/AvatarCacheProvider/AvatarCacheProvider'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import { GET_PERSON_BASIC } from '../People/queries'
import Row from '../Row/Row'

type ContactAvatarProps = {
  email?: string
  size?: number
  border?: number
  borderRadius?: number
  style?: React.CSSProperties
  showSidebar?: boolean
  useFallback?: boolean
  passedPhotoUrl?: string
  dayObject?: DayObject
}

const getCacheKey = (props: ContactAvatarProps): string => {
  const {
    email,
    size,
    border,
    borderRadius,
    showVerification,
    showSidebar,
    useFallback,
    passedPhotoUrl,
    dayObject,
  } = props
  return `${email}-${size}-${border}-${borderRadius}-${showVerification}-${showSidebar}-${useFallback}-${passedPhotoUrl}-${dayObject ? 'true' : 'false'}`
}

const BaseContactAvatar = memo(
  ({
    email,
    size,
    border,
    borderRadius = 100,
    style,
    showSidebar = false,
    passedPhotoUrl = null,
    dayObject = null,
  }: ContactAvatarProps) => {
    const { setSidebarObject } = useContext(DayContext)

    const shouldQueryPhoto = useMemo(() => {
      return !passedPhotoUrl && email && email !== 'assistant@day.ai'
    }, [email, passedPhotoUrl])

    const { data: personData } = useQuery(GET_PERSON_BASIC, {
      variables: { email },
      skip: !shouldQueryPhoto,
    })

    let photoUrl = passedPhotoUrl || personData?.getPersonPublic?.photoUrl

    const fontSize: string = size ? `${Math.floor(0.42 * size)}px` : '48px'

    if (!border) border = 0

    // Hardcode assistant photo
    if (email === 'assistant@day.ai')
      photoUrl = `${process.env.HOST}/logos/Day 1.png`

    const finalBorderRadius = borderRadius.toString() + 'px'

    const initials = useMemo(() => {
      const firstName =
        personData?.getPersonPublic?.firstName ||
        (dayObject?.properties?.standard as any)?.firstName?.value
      if (firstName) {
        const lastName =
          personData?.getPersonPublic?.lastName ||
          (dayObject?.properties?.standard as any)?.lastName?.value
        logger.dev('ContactAvatar: Initials', {
          firstName,
          lastName,
        })
        return `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`
          .toUpperCase()
          .trim()
      } else {
        return typeof email === 'string'
          ? `${email?.charAt(0) || ''}`.toUpperCase().trim()
          : null
      }
    }, [personData, email, dayObject])

    const avatarContent = photoUrl ? (
      <Avatar
        src={photoUrl}
        sx={{
          height: `${size}px`,
          width: `${size}px`,
          background: (theme) => theme.palette.primary.main,
          border: (theme) =>
            `${border}px solid ${theme.palette.background.paper} !important`,
          fontSize,
          objectFit: 'cover',
          fontWeight: 600,
          borderRadius: finalBorderRadius,
          overflow: 'hidden',
          textDecoration: 'none',
          '&:not(a)': {
            textDecoration: 'none',
          },
          '& .MuiAvatar-fallback': {},
          ...style,
        }}
      />
    ) : (
      <Avatar
        sx={{
          height: `${size}px`,
          width: `${size}px`,
          borderRadius: finalBorderRadius,
          overflow: 'hidden',
          backgroundColor: (theme) => theme.palette.text.primary,
          border: (theme) =>
            `${border}px solid ${theme.palette.background.paper} !important`,
        }}
      >
        <Row
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Typography
            sx={{
              fontSize: `${fontSize} !important`,
              height: `calc(${Math.floor(size / 2)}px + ${Math.floor(
                size / 20
              )}px) !important`,
              color: (theme) => theme.palette.primary.contrastText,
              fontWeight: '600 !important',
              lineHeight: '100% !important',
              mb: `${-1 * Math.floor(size / 10)}px !important`,
            }}
          >
            {initials}
          </Typography>
        </Row>
      </Avatar>
    )

    return (
      <Box
        className="personAvatarBox"
        sx={{
          display: 'inline-flex',
          verticalAlign: 'middle',
        }}
        onClick={(e) => {
          if (showSidebar) {
            e.stopPropagation()
            setSidebarObject({
              objectId: email,
              objectType: NativeObjectTypes.Contact,
              properties: {},
            })
          }
        }}
      >
        {avatarContent}
      </Box>
    )
  }
)

const CachedContactAvatar = (props: ContactAvatarProps) => {
  const { getCachedAvatar, setCachedAvatar } = useAvatarCache()
  const cacheKey = getCacheKey(props)

  const cachedAvatar = getCachedAvatar(cacheKey)
  if (cachedAvatar) {
    return cachedAvatar
  }

  const newAvatar = <BaseContactAvatar {...props} />
  setCachedAvatar(cacheKey, newAvatar)
  return newAvatar
}

export default CachedContactAvatar
