import { useCallback, useContext, useMemo } from 'react'
import { useState } from 'react'

import {
  Checkbox,
  Tooltip,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
  TextField,
  Box,
  MenuList,
  Tabs,
  Tab,
} from '@mui/material'
import { IconButton, ListItem, Menu } from '@mui/material'
import {
  IconPlus,
  IconSearch,
  IconStack,
  IconStack2Filled,
  IconStackFilled,
  IconX,
} from '@tabler/icons-react'

import { useAuth } from 'src/auth'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

import ContactAvatar from '../ContactAvatar/ContactAvatar'
import DomainAvatar from '../DomainAvatar/DomainAvatar'
import Row from '../Row/Row'
import WorkspaceMemberChip from '../WorkspaceMemberChip/WorkspaceMemberChip'

import useViews from './useViews'

type ViewCreateState = 'pin' | null

const ViewCreate = () => {
  const {
    views,
    pinnedViews,
    createView,
    addPin,
    removePin,
    saving,
    currentView,
  } = useViews()
  const { currentUser } = useAuth()
  const { selectedWorkspace, workspaces } = useContext(DayContext)

  const workspace = useMemo(() => {
    return (workspaces ?? []).find((w) => w.id === selectedWorkspace)
  }, [workspaces, selectedWorkspace])

  const workspaceDomain = useMemo(() => {
    return workspace?.domains?.find((d) => !!d.domain)?.domain ?? null
  }, [workspace])

  const [search, setSearch] = useState('')
  const [showWorkspaceViews, setShowWorkspaceViews] = useState(false)

  const [addViewState, setAddViewState] = useState<ViewCreateState>(null)

  const [createMenuEl, setCreateMenuEl] = useState<null | HTMLElement>(null)

  const handleMenuClose = useCallback(() => {
    setCreateMenuEl(null)
    setAddViewState(null)
  }, [setAddViewState])

  const viewsFiltered = useMemo(() => {
    const searchMatches =
      search.length > 0
        ? views.filter((v) =>
            v.title.toLowerCase().includes(search.toLowerCase())
          )
        : views

    const finalViews = searchMatches.filter((v) => {
      if (showWorkspaceViews) {
        return v.shareWithWorkspace
      }
      return v.creatorId === currentUser?.id
    })

    return finalViews.filter(Boolean)
  }, [views, search, showWorkspaceViews, currentUser])

  const handleCreateViewFromCurrent = useCallback(async () => {
    if (saving) return

    try {
      const title = `${currentView?.title ?? 'Untitled View'} (copy)`
      const description = currentView?.description ?? ''
      const shareWithWorkspace = currentView?.shareWithWorkspace ?? false

      await createView(
        {
          title,
          description,
          shareWithWorkspace,
        },
        { useCurrentState: true }
      )
      handleMenuClose()
    } catch (error) {
      logger.error('Error creating view from current state:', error)
    }
  }, [createView, currentView, handleMenuClose, saving])

  const handleCreateScratchView = useCallback(async () => {
    if (saving) return
    try {
      handleMenuClose()
      await createView(
        {
          title: 'New View',
          description: '',
          shareWithWorkspace: false,
        },
        { useCurrentState: false }
      )
    } catch (error) {
      logger.error('Error creating scratch view:', error)
    }
  }, [createView, handleMenuClose, saving])

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setCreateMenuEl(event.currentTarget)
    },
    []
  )

  const pinnedViewsIds = useMemo(() => {
    return pinnedViews?.map((v) => v.id) || []
  }, [pinnedViews])

  const handleTogglePin = useCallback(
    (viewId: string) => {
      if (pinnedViewsIds.includes(viewId)) {
        removePin(viewId)
      } else {
        addPin(viewId)
      }
    },
    [pinnedViewsIds, addPin, removePin]
  )

  const listItemIconSx = useMemo(() => ({ minWidth: '32px' }), [])

  const backButtonSx = useMemo(
    () => ({
      p: '3px',
      opacity: 0.5,
      '&:hover': { opacity: 1 },
      borderRadius: '2px',
    }),
    []
  )

  const pinnedItemTextSx = useMemo(() => ({ my: 0 }), [])
  const pinnedItemPrimaryTextSx = useMemo(
    () => ({ fontSize: '12px', fontWeight: 500, letterSpacing: '-0.3px' }),
    []
  )

  const noViewsTextSx = useMemo(
    () => ({ fontSize: '12px', color: 'text.secondary' }),
    []
  )
  const noViewsListItemSx = useMemo(
    () => ({ py: 2, justifyContent: 'center' }),
    []
  )

  const menuSx = useMemo(
    () => ({
      '& .MuiPaper-root': { width: '480px' },
      '& .MuiList-root': { py: 0 },
      '& .MuiListItemText-primary': {
        fontSize: '11px',
        opacity: 0.9,
        letterSpacing: '-0.2px',
      },
      '& .MuiListItemIcon-root': {
        minWidth: '24px',
      },
      '& .views-manage-controls': {
        boxShadow: (theme) => theme.shadows[1],
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,

        '& .views-manage-controls-title': {
          fontSize: '13px',
          fontWeight: 600,
          letterSpacing: '-0.4px',
        },
        '& .views-manage-controls-header': {
          width: '100%',
          justifyContent: 'space-between',
          p: '12px',
        },
        '& .views-search': {
          pl: '12px',
        },

        '& .views-workspace-toggle-tabs': {
          '&.MuiTabs-root': {
            flexShrink: 0,
            p: 0,
            m: 0,
            minHeight: '0px',
            '& .MuiTab-root': {
              minHeight: '0px',
              height: '48px',
              fontSize: '11px',
              fontWeight: 500,
              letterSpacing: '-0.3px',
              width: '72px',
              minWidth: '72px',
              opacity: 0.5,
              p: 0,
              m: 0,

              '&.Mui-selected': {
                opacity: 1,
                fontWeight: 600,
              },
            },
          },
        },
      },
      '& .views-list': {
        maxHeight: '340px',
        overflowY: 'auto',
        justifyContent: 'space-between',

        '& .MuiChip-root': {
          width: 'auto',
        },

        '& .view-item': {
          py: '6px',
          px: '0px',
          height: '36px',
          background: (theme) => theme.palette.background.default,
          '& .MuiSvgIcon-root': {
            transform: 'scale(0.8)',
          },
        },
      },
    }),
    []
  )

  const ManageViews = () => {
    return (
      <Box className="manage-views">
        <Box
          className="views-manage-controls"
          key="views-manage-controls"
        >
          <Row
            gap={1}
            className="views-manage-controls-header"
          >
            <Typography className="views-manage-controls-title">
              Manage Views
            </Typography>
            <IconButton
              onClick={() => setAddViewState(null)}
              sx={backButtonSx}
            >
              <IconX
                size={16}
                stroke={2.5}
              />
            </IconButton>
          </Row>
          <Row
            key="views-search"
            className="views-search"
          >
            <TextField
              size="small"
              variant="standard"
              fullWidth={true}
              sx={{ width: '100%' }}
              InputProps={{
                disableUnderline: true,
                startAdornment: <IconSearch size={16} />,
                sx: {
                  '& input': {
                    pl: '6px',
                  },
                },
              }}
              value={search}
              onChange={(e) => {
                e.stopPropagation()
                setSearch(e.target.value)
              }}
            />
            <Tabs
              className="views-workspace-toggle-tabs"
              value={showWorkspaceViews}
              onChange={(_, value) => setShowWorkspaceViews(value)}
              TabIndicatorProps={{
                sx: {
                  height: '0px',
                },
              }}
            >
              <Tab
                label="My views"
                value={false}
                icon={
                  <ContactAvatar
                    email={currentUser?.email}
                    size={16}
                  />
                }
              />
              <Tab
                label={`${workspace.name}`}
                value={true}
                icon={
                  <DomainAvatar
                    domain={workspaceDomain}
                    size={16}
                  />
                }
              />
            </Tabs>
          </Row>
        </Box>
        <MenuList className="views-list">
          {viewsFiltered.length > 0
            ? viewsFiltered.map((v) => (
                <ListItemButton
                  key={v.id}
                  onClick={() => handleTogglePin(v.id)}
                  className="view-item"
                >
                  <ListItemIcon sx={listItemIconSx}>
                    <Checkbox
                      checked={pinnedViewsIds.includes(v.id)}
                      size="small"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={v.title}
                    primaryTypographyProps={pinnedItemPrimaryTextSx}
                    sx={pinnedItemTextSx}
                  />
                  <WorkspaceMemberChip userId={v.creatorId} />
                </ListItemButton>
              ))
            : [
                <ListItem
                  key="no-views"
                  sx={noViewsListItemSx}
                >
                  <Typography sx={noViewsTextSx}>
                    No workspace views found.
                  </Typography>
                </ListItem>,
              ]}
        </MenuList>
      </Box>
    )
  }

  const ChooseViewState = () => {
    return [
      <ListItemButton
        key="create-scratch-view"
        onClick={handleCreateScratchView}
        disabled={saving}
      >
        <ListItemIcon sx={listItemIconSx}>
          <IconStack size={16} />
        </ListItemIcon>
        <ListItemText primary="Create view from scratch" />
      </ListItemButton>,
      <ListItemButton
        key="create-view-from-current"
        onClick={handleCreateViewFromCurrent}
        disabled={saving}
      >
        <ListItemIcon sx={listItemIconSx}>
          <IconStackFilled size={16} />
        </ListItemIcon>
        <ListItemText primary="Save current as new view" />
      </ListItemButton>,
      <ListItemButton
        key="workspace-views"
        onClick={() => setAddViewState('pin')}
        disabled={saving}
      >
        <ListItemIcon sx={listItemIconSx}>
          <IconStack2Filled size={16} />
        </ListItemIcon>
        <ListItemText primary={`Manage views`} />
      </ListItemButton>,
    ]
  }

  return (
    <>
      <Tooltip
        title="Add view"
        arrow={true}
        placement="top"
      >
        <IconButton
          onClick={handleOpenMenu}
          className="flat"
          disabled={saving}
        >
          <IconPlus size={14} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={createMenuEl}
        open={Boolean(createMenuEl)}
        onClose={handleMenuClose}
        sx={menuSx}
      >
        {!addViewState && <ChooseViewState />}

        {addViewState === 'pin' && <ManageViews />}
      </Menu>
    </>
  )
}

export default ViewCreate
