/**
 * Updates positions for a collection of items when one item's position changes
 *
 * @param {Array<{id: string, position: number}>} items - Current collection of items with positions
 * @param {string} targetId - ID of the item being updated
 * @param {number|null} newPosition - New position to assign (null/undefined to remove)
 * @returns {Array<{id: string, position: number|undefined}>} - Updated items with correct positions
 */
export const calculateOrderedPositions = (items, targetId, newPosition) => {
  // Make a copy to avoid mutating the original
  const result = items.map((item) => ({ ...item }))

  // Find the target item
  const targetIndex = result.findIndex((item) => item.id === targetId)
  const targetItem = targetIndex !== -1 ? result[targetIndex] : null
  const oldPosition = targetItem?.position

  // Case 1: Removing a pin (newPosition is null/undefined)
  if (newPosition === null || newPosition === undefined) {
    if (targetIndex !== -1 && typeof oldPosition === 'number') {
      // Remove position from target item
      result[targetIndex] = { ...targetItem, position: undefined }

      // Shift down items that had higher positions
      for (let i = 0; i < result.length; i++) {
        if (
          i !== targetIndex &&
          typeof result[i].position === 'number' &&
          result[i].position > oldPosition
        ) {
          result[i] = { ...result[i], position: result[i].position - 1 }
        }
      }
    }
  }
  // Case 2: Adding or updating a position
  else {
    // Ensure newPosition is within valid range
    const maxExistingPosition = Math.max(
      -1,
      ...result
        .filter(
          (item) => item.id !== targetId && typeof item.position === 'number'
        )
        .map((item) => item.position)
    )
    const clampedPosition = Math.min(
      Math.max(0, newPosition),
      maxExistingPosition + 1
    )

    // Case 2a: Item already has a position (moving an existing pinned item)
    if (targetIndex !== -1 && typeof oldPosition === 'number') {
      // Skip if position didn't change
      if (oldPosition === clampedPosition) {
        return result
      }

      // Update the item's position
      result[targetIndex] = { ...targetItem, position: clampedPosition }

      // Reorder other items
      if (oldPosition < clampedPosition) {
        // Moving down: shift up items that are between old and new positions
        for (let i = 0; i < result.length; i++) {
          if (
            i !== targetIndex &&
            typeof result[i].position === 'number' &&
            result[i].position > oldPosition &&
            result[i].position <= clampedPosition
          ) {
            result[i] = { ...result[i], position: result[i].position - 1 }
          }
        }
      } else {
        // Moving up: shift down items that are between new and old positions
        for (let i = 0; i < result.length; i++) {
          if (
            i !== targetIndex &&
            typeof result[i].position === 'number' &&
            result[i].position >= clampedPosition &&
            result[i].position < oldPosition
          ) {
            result[i] = { ...result[i], position: result[i].position + 1 }
          }
        }
      }
    }
    // Case 2b: Item doesn't have a position yet (pinning a new item)
    else if (targetIndex !== -1) {
      // Update the item's position
      result[targetIndex] = { ...targetItem, position: clampedPosition }

      // Shift up items with positions equal to or higher than the new position
      for (let i = 0; i < result.length; i++) {
        if (
          i !== targetIndex &&
          typeof result[i].position === 'number' &&
          result[i].position >= clampedPosition
        ) {
          result[i] = { ...result[i], position: result[i].position + 1 }
        }
      }
    }
    // Case 2c: Item doesn't exist in the collection (should not normally happen)
    else if (targetId) {
      console.warn(`Item with id ${targetId} not found in collection`)
    }
  }

  // Normalize positions to ensure they are sequential (0, 1, 2, ...)
  const pinnedItems = result
    .filter((item) => typeof item.position === 'number')
    .sort((a, b) => a.position - b.position)

  // Assign normalized positions
  pinnedItems.forEach((item, index) => {
    const resultIndex = result.findIndex((r) => r.id === item.id)
    if (resultIndex !== -1 && result[resultIndex].position !== index) {
      result[resultIndex] = { ...result[resultIndex], position: index }
    }
  })

  return result
}
