import { useContext, useState, useEffect, useMemo } from 'react'

import { Avatar, Box, Chip, lighten, Popper } from '@mui/material'
import { IconCircleFilled } from '@tabler/icons-react'
import { Node, mergeAttributes } from '@tiptap/core'
import type { Node as ProseMirrorNode } from '@tiptap/pm/model'
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react'
import type { SuggestionOptions } from '@tiptap/suggestion'

import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ContactAvatar from 'src/components/ContactAvatar/ContactAvatar'
import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import ObjectPreviewMenu from 'src/components/ObjectPreviewMenu/ObjectPreviewMenu'
import {
  GET_CHIP_METADATA,
  GET_CONTACT_BY_EMAIL_FOR_CHIP,
} from 'src/components/Objects/queries'
import { getDayObjectSearchEntry } from 'src/components/Objects/searchIndex'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { getBestLabel } from 'src/lib/objectLabels'
import { NativeObjectTypes, ObjectTypeMetadata } from 'src/lib/objects'

interface ObjectChipNodeAttrs {
  objectType: string | null
  objectId: string | null
  displayName: string | null
}

type ObjectChipOptions<
  SuggestionItem = any,
  Attrs extends Record<string, any> = ObjectChipNodeAttrs,
> = {
  HTMLAttributes: Record<string, any>
  suggestion: Omit<SuggestionOptions, 'editor'>
  renderHTML: (props: {
    options: ObjectChipOptions<SuggestionItem, Attrs>
    node: ProseMirrorNode
  }) => HTMLElement
  renderText: (props: {
    options: ObjectChipOptions<SuggestionItem, Attrs>
    node: ProseMirrorNode
  }) => string
}

const USE_SEARCH_INDEX_FIRST = true

const renderPlainParents = ['heading', 'title']

const chipSx = {
  height: '18px',
  fontWeight: 600,
  borderRadius: '4px',
  border: `1px solid transparent !important`,
  px: '1px',
  mb: '1px',
  flexShrink: 0,
  '& .MuiChip-label': {
    pr: '2px !important',
    pl: '4px !important',
    fontSize: '12px',
    letterSpacing: '-0.22px',
  },
  '& .personAvatarBox, .personAvatarBox .MuiAvatar-root': {
    height: '12px !important',
    width: '12px !important',
  },
  '&:hover': {
    background: lighten('#B4D7FF', 0.8),
  },
  '&.selected': {
    background: lighten('#B4D7FF', 0.8),
    border: `1px solid #B4D7FF !important`,
    color: '#2867B2',
  },
}

const ObjectChipComponent = (props) => {
  const { node } = props
  const resolvedPos = props.editor.state.doc.resolve(props.getPos())
  const parent = resolvedPos.parent

  const { currentUser } = useAuth()
  const { setSidebarObject, selectedWorkspace } = useContext(DayContext)
  const { objectType, objectId, displayName } = node.attrs

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClick = () => {
    setSidebarObject({
      objectType,
      objectId,
      properties: {},
    })
  }

  const [localMetadata, setLocalMetadata] = useState<{
    label: string | null
    avatarUrl: string | null
  }>({ label: null, avatarUrl: null })

  const { data: chipData } = useQuery(GET_CHIP_METADATA, {
    variables: {
      objectId,
      objectType,
      workspaceId: selectedWorkspace,
    },
    skip:
      !objectId ||
      !objectType ||
      !selectedWorkspace ||
      [NativeObjectTypes.MeetingRecording].includes(objectType as any),
  })

  const chipMetadata = chipData?.chipMetadata
  logger.dev('TiptapObjectChip: chipMetadata', { chipMetadata })

  useEffect(() => {
    if (chipMetadata) {
      setLocalMetadata({
        label: chipMetadata.label,
        avatarUrl: chipMetadata.avatarUrl,
      })
      return
    }

    if (USE_SEARCH_INDEX_FIRST && selectedWorkspace && objectType && objectId) {
      const fetchLocal = async () => {
        try {
          const searchEntry = await getDayObjectSearchEntry({
            workspaceId: selectedWorkspace,
            objectType: objectType as any,
            objectId,
          })

          if (searchEntry) {
            const newLocalMetadata = {
              label: searchEntry.label || null,
              avatarUrl: searchEntry.photoUrl || null,
            }
            setLocalMetadata(newLocalMetadata)
          } else {
            setLocalMetadata({ label: null, avatarUrl: null })
          }
        } catch (error) {
          logger.warn('TiptapObjectChip: Error fetching from searchIndex', {
            error,
            objectId,
            objectType,
          })
          setLocalMetadata({ label: null, avatarUrl: null })
        }
      }
      fetchLocal()
    } else if (!USE_SEARCH_INDEX_FIRST) {
      logger.dev('TiptapObjectChip: Skipping searchIndex fetch due to flag')
      setLocalMetadata({ label: null, avatarUrl: null })
    }
  }, [selectedWorkspace, objectType, objectId, chipMetadata])

  const { data: personData } = useQuery(GET_CONTACT_BY_EMAIL_FOR_CHIP, {
    variables: {
      contactEmail: objectId,
      ownerEmail: currentUser?.email,
    },
    skip:
      !objectId ||
      !currentUser?.email ||
      objectType != NativeObjectTypes.Contact,
  })

  const fallbackAvatar = useMemo(() => {
    if (objectType === NativeObjectTypes.Organization) {
      return (
        <DomainAvatar
          domain={objectId}
          size={18}
          photoUrl={localMetadata.avatarUrl}
        />
      )
    } else if (objectType === NativeObjectTypes.Person) {
      return (
        <ContactAvatar
          email={objectId}
          size={18}
          passedPhotoUrl={localMetadata.avatarUrl}
        />
      )
    } else {
      const objectIcon =
        ObjectTypeMetadata[objectType]?.icon || IconCircleFilled
      return (
        <Avatar
          className="domainAvatar"
          sx={{
            height: '18px',
            width: '18px',
            ml: '0 !important',
            mr: '0 !important',
          }}
        >
          {React.createElement(objectIcon, {
            size: 10,
            stroke: 2.25,
          })}
        </Avatar>
      )
    }
  }, [objectType, objectId, localMetadata.avatarUrl])

  const finalAvatar = useMemo(() => {
    if (localMetadata.avatarUrl) {
      if (objectType === NativeObjectTypes.Organization) {
        return (
          <DomainAvatar
            domain={objectId}
            size={18}
            photoUrl={localMetadata.avatarUrl}
          />
        )
      } else if (objectType === NativeObjectTypes.Contact) {
        return (
          <ContactAvatar
            email={objectId}
            size={18}
            passedPhotoUrl={localMetadata.avatarUrl}
          />
        )
      }
    }
    return fallbackAvatar
  }, [objectType, objectId, localMetadata.avatarUrl, fallbackAvatar])

  const useChip = !renderPlainParents.includes(parent.type.name)
  const crmObject = personData?.getContactByEmail || {}

  const bestLabel = useMemo(() => {
    const retrievedLabel = getBestLabel({
      passedProperties: crmObject.properties as Record<string, any>,
      localMetadata,
      serverMetadata: chipMetadata,
      objectType,
      objectId,
    })
    return retrievedLabel || displayName
  }, [
    crmObject.properties,
    localMetadata,
    chipMetadata,
    objectType,
    objectId,
    displayName,
  ])

  const wrapperStyle = useMemo(() => {
    return { display: useChip ? 'inline-block' : 'inline', margin: '0px -2px' }
  }, [useChip])

  return (
    <NodeViewWrapper
      as="span"
      style={wrapperStyle}
      draggable={props.editor.isEditable}
    >
      <Box
        component="span"
        onMouseLeave={() => setAnchorEl(null)}
      >
        {useChip ? (
          <Chip
            avatar={finalAvatar}
            label={bestLabel}
            size="small"
            variant="outlined"
            color="primary"
            clickable={true}
            className={`day-ai-node day-ai-object-chip ${
              props.editor.isEditable ? 'editable' : 'public'
            } ${props.selected ? 'selected' : ''}`}
            sx={chipSx}
            onClick={handleClick}
            onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
          />
        ) : (
          <Box
            component="span"
            sx={{
              color: (theme) =>
                props.selected ? '#2867B2' : theme.palette.secondary.dark,
              cursor: 'pointer',
              wordBreak: 'break-word',
              '&:hover': {
                color: (theme) => theme.palette.secondary.main,
              },
              background: props.selected && lighten('#B4D7FF', 0.8),
              ml: '3px',
            }}
            onClick={handleClick}
            onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
          >
            {bestLabel}
          </Box>
        )}

        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="auto"
          sx={{ zIndex: 1300 }}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                padding: 8,
                altAxis: true,
              },
            },
          ]}
        >
          <ObjectPreviewMenu
            objectType={objectType}
            objectId={objectId}
            workspaceId={selectedWorkspace}
          />
        </Popper>
      </Box>
    </NodeViewWrapper>
  )
}

const TiptapObjectChip = Node.create<ObjectChipOptions>({
  name: 'objectChip',
  inline: true,
  group: 'inline',
  selectable: true,

  addAttributes() {
    return {
      objectType: {
        default: null,
      },
      objectId: {
        default: null,
      },
      displayName: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        { 'data-type': this.name },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      node.attrs.displayName,
    ]
  },

  renderText({ node }) {
    return node.attrs.displayName
  },

  addNodeView() {
    return ReactNodeViewRenderer(ObjectChipComponent)
  },
})

export default TiptapObjectChip
