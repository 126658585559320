import { useCallback, useRef, useState, useEffect, useMemo } from 'react'

import {
  Checkbox,
  IconButton,
  Menu,
  TextField,
  ListItem,
  FormControlLabel,
  Typography,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tooltip,
  Box,
} from '@mui/material'
import {
  IconChevronDown,
  IconChevronLeft,
  IconProgressCheck,
  IconGripVertical,
} from '@tabler/icons-react'
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import type { View } from 'types/graphql'

import { dayjs } from 'src/lib/dayjs'

import Row from '../Row/Row'
import WorkspaceMemberChip from '../WorkspaceMemberChip/WorkspaceMemberChip'

import useViews from './useViews'
import ViewDelete from './ViewDelete'
import ViewRemovePin from './ViewRemovePin'

const ViewTab = ({
  view,
  currentView,
  setCurrentView,
  dragHandleProps,
}: {
  view: View
  currentView: View
  setCurrentView: (_) => void
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined
}) => {
  const { updateView, saving, loading } = useViews()

  const [createMenuEl, setCreateMenuEl] = useState<null | HTMLElement>(null)
  const [title, setViewTitle] = useState(currentView?.title || '')
  const [description, setDescription] = useState(currentView?.description || '')
  const [showDescription, setShowDescription] = useState(true)
  const [shareWithWorkspace, setShareWithWorkspace] = useState(
    currentView?.shareWithWorkspace || false
  )

  // Update local state when currentView changes
  useEffect(() => {
    if (currentView) {
      setViewTitle(currentView.title || '')
      setDescription(currentView.description || '')
      setShareWithWorkspace(currentView.shareWithWorkspace || false)
    }
  }, [currentView])

  const handleUpdate = useCallback(async () => {
    if (!currentView?.id || !title.trim()) return

    try {
      await updateView({
        id: currentView.id,
        input: {
          title: title.trim(),
          description: description.trim(),
          shareWithWorkspace,
        },
      })
      setCreateMenuEl(null)
    } catch (error) {
      console.error('Error updating view:', error)
    }
  }, [updateView, currentView, title, description, shareWithWorkspace])

  const titleRef = useRef<HTMLInputElement>(null)

  const handleEdit = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setCreateMenuEl(event.currentTarget)
      setTimeout(() => {
        titleRef.current?.focus()
      }, 100)
    },
    []
  )

  const handleTitleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setViewTitle(e.target.value)
    },
    []
  )

  const handleTitleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation()
      if (e.key === 'Enter') {
        handleUpdate()
      }
    },
    [handleUpdate]
  )

  const handleDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation()
      setDescription(e.target.value)
    },
    []
  )

  const handleDescriptionKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation()
    },
    []
  )

  const handleDescriptionToggle = useCallback(() => {
    setShowDescription((prev) => !prev)
  }, [])

  const handleWorkspaceShareChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setShareWithWorkspace(e.target.checked)
    },
    []
  )

  const isSelected = useMemo(() => {
    return currentView?.id === view.id
  }, [currentView, view])

  useEffect(() => {
    const element = document.getElementById(`view-tab-${view.id}`)
    if (element && isSelected) {
      //check to see if the right side of the element is visible
      const rect = element.getBoundingClientRect()

      const parentContainer = document.querySelector('.view-manager-tabs')
      const parentContainerRightEdge =
        parentContainer?.getBoundingClientRect().right
      const isVisible = rect.right <= parentContainerRightEdge
      if (!isVisible) {
        element.scrollIntoView({ behavior: 'instant' })
      }
    }
  }, [view.id, isSelected])

  // Only render if we have a current view
  if (!currentView) return null

  const containerSx = {
    display: 'flex',
    alignItems: 'center',
    ml: '4px',
    '&:hover': {
      '& .view-tab-drag-handle': {
        opacity: 1,
      },
    },
    '& .view-tab-drag-handle': {
      opacity: 0,
      transition: 'opacity 0.2s ease-in-out',
      alignItems: 'center',
      padding: '0 4px 0 0',
      cursor: 'grab',
      color: 'action.active',
      marginRight: '-24px',
      zIndex: 1,
      '&:hover': {
        backgroundColor: 'action.hover',
        borderRadius: '4px',
      },
    },
    '& .view-tab-menu': {
      pb: 0,
      '& .MuiList-root': {
        pb: 0,
        '& .MuiListItemButton-root': {
          px: '14px',
          '& .MuiListItemIcon-root': {
            minWidth: '25px',
          },
        },
        '& .view-tab-description': {
          py: '4px',
        },
        '& .view-tab-share-with-workspace': {
          py: '4px',
        },
      },
    },
  }

  return (
    <Box sx={containerSx}>
      <Box
        {...dragHandleProps}
        className="view-tab-drag-handle"
      >
        <IconGripVertical size={16} />
      </Box>

      <Tooltip
        title={
          isSelected
            ? `(click to edit) ${view.description || ''}`.trim()
            : `${view.description || ''}`.trim()
        }
        arrow={true}
        placement="top"
      >
        <Tab
          className={`view-tab ${isSelected ? 'Mui-selected' : ''}`}
          key={view.id}
          sx={{
            paddingLeft: '12px',
          }}
          label={
            <Row sx={{ justifyContent: 'space-between' }}>
              <Typography>{view.title}</Typography>
              {currentView?.id === view.id && (
                <Row sx={{ ml: '6px' }}>
                  <ViewRemovePin />
                </Row>
              )}
            </Row>
          }
          value={view.id}
          disableFocusRipple={true}
          disabled={loading}
          onClick={(e) => {
            if (isSelected) {
              handleEdit(e as unknown as React.MouseEvent<HTMLButtonElement>)
            } else {
              setCurrentView(view.id)
            }
          }}
        />
      </Tooltip>

      <Menu
        anchorEl={createMenuEl}
        open={!!createMenuEl}
        onClose={() => setCreateMenuEl(null)}
        className="view-tab-menu"
        slotProps={{
          paper: {
            sx: {
              '& .MuiList-root.MuiMenu-list': {
                pb: 0,
              },
            },
          },
        }}
      >
        <ListItem>
          <Row
            sx={{ width: '320px' }}
            gap={'4px'}
          >
            <TextField
              inputRef={titleRef}
              label="Title"
              value={title}
              onChange={handleTitleChange}
              onKeyDown={handleTitleKeyDown}
              fullWidth={true}
              size="small"
            />
            <IconButton
              onClick={handleDescriptionToggle}
              sx={{
                p: '4px',
                borderRadius: '3px',
              }}
            >
              {!showDescription ? (
                <IconChevronLeft size={20} />
              ) : (
                <IconChevronDown size={20} />
              )}
            </IconButton>
          </Row>
        </ListItem>

        {showDescription && (
          <ListItem className="view-tab-description">
            <TextField
              label="Description"
              value={description}
              onChange={handleDescriptionChange}
              onKeyDown={handleDescriptionKeyDown}
              fullWidth={true}
              size="small"
              multiline={true}
              rows={3}
              autoComplete="off"
            />
          </ListItem>
        )}
        {showDescription && view.shareWithWorkspace && (
          <ListItem className="view-creator">
            <Row sx={{ width: '100%' }}>
              <Box
                sx={{
                  '& .MuiChip-root': {
                    width: 'auto !important',
                    '& .MuiChip-label': {
                      pr: '3px',
                    },
                  },
                }}
              >
                <WorkspaceMemberChip
                  userId={view.creatorId}
                  workspaceId={view.workspaceId}
                />
              </Box>
              <Typography
                className="view-created-at"
                sx={{
                  flexShrink: 0,
                  fontSize: '11px',
                  fontWeight: 600,
                  letterSpacing: '-0.22px',
                  lineHeight: '14px',
                  opacity: 0.9,
                }}
              >
                {`created on ${dayjs(view.createdAt).format('M/D/YY')}`}
              </Typography>
            </Row>
          </ListItem>
        )}
        <ListItem className="view-tab-share-with-workspace">
          <FormControlLabel
            control={
              <Checkbox
                checked={shareWithWorkspace}
                onChange={handleWorkspaceShareChange}
                size="small"
                sx={{
                  opacity: 0.6,
                  '&.Mui-checked': {
                    opacity: 0.8,
                    '& .MuiSvgIcon-root': { height: '16px', width: '16px' },
                  },
                }}
              />
            }
            label="Share with workspace"
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '12px',
                letterSpacing: '-0.22px',
              },
            }}
          />
        </ListItem>

        <ViewDelete />
        <ListItemButton
          onClick={handleUpdate}
          disabled={!title.trim() || saving}
        >
          <ListItemIcon>
            <IconProgressCheck size={16} />
          </ListItemIcon>
          <ListItemText
            primary={saving ? 'Updating...' : 'Update'}
            primaryTypographyProps={{
              fontSize: '12px',
              letterSpacing: '-0.22px',
              fontWeight: 600,
            }}
          />
        </ListItemButton>
      </Menu>
    </Box>
  )
}

export default ViewTab
